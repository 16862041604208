/*Mobile version*/
.arets-nisse-container {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.example-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.example-object {
  margin: 20px auto;
  width: 90%;
}

.grey-container {
  background-color: rgb(174, 176, 171);
  padding-top: 20px;
  padding-bottom: 5px;
}

/* Desktop version */
@media screen and (min-width: 768px) {
  .arets-nisse-container {
    flex-direction: row;
  }

  .example-container {
    flex-direction: row;
    margin-top: 80px;
  }

  .example-object {
    margin: auto 20px;
    width: 55%;
  }
}
