.start-image {
  width: 100%;
}

.start-image-text {
  color: white;
  position: absolute;
  top: 25%;
  left: 75%;
  width: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
}

.nisseprat-logo {
  width: 80%;
}

.start-image-text-item {
  margin: 5px;
  font-weight: bold;
}

.text-container {
  display: flex;
  flex-direction: column;
  margin: 20px auto 0 auto;
  align-items: center;
  text-align-last: center;
  background-color: rgba(255, 255, 255, 0.75);
  margin: 0 5%;
  border-radius: 20px;
}

.text-header {
  font-size: 26px;
  border-bottom: solid rgb(156, 111, 37) 3px;
  margin: 20px;
}

.text-item {
  width: 90%;
  font-size: 18px;
  margin: 10px;
  text-align: center;
}

.images-container {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.image-row {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgb(174, 176, 171);
}

/* Small desktop */
@media screen and (max-width: 1080px) and (min-width: 768px) {
  .start-image-text {
    top: 30%;
  }

  .start-image-text {
    font-size: 24px;
  }

  .text-item {
    width: 90%;
    font-size: 24px;
  }
}

/* Desktop */
@media screen and (min-width: 1080px) {
  .start-image-text {
    top: 50%;
  }

  .start-image-text {
    font-size: 32px;
  }

  .text-header {
    font-size: 50px;
  }

  .text-item {
    width: 90%;
    font-size: 24px;
  }
}
