.homepage-container {
  margin-top: -80px;
}

.btn-to-checkout {
  background-color: rgb(1, 52, 8);
  color: white;
  margin: 20px 0;
  padding: 5px 15px;
  font-size: large;
  border: none;
}

.btn-to-checkout:hover {
  cursor: pointer;
  background-color: rgb(85, 124, 63);
}

.about-container {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(174, 176, 171);
}

.door-image {
  width: 80%;
  padding-top: 10px;
}

/* Small desktop */
@media screen and (max-width: 1080px) and (min-width: 768px) {
  .about-container {
    flex-direction: row;
  }

  .door-image {
    width: 40%;
    padding: 10px 20px 0 0;
  }
}

/* Desktop */
@media screen and (min-width: 1080px) {
  .about-container {
    flex-direction: row;
  }

  .door-image {
    width: 40%;
    padding: 10px 20px 0 0;
  }
}
