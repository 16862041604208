.checkout-form-container {
  display: flex;
  justify-content: center;
  background-color: rgb(171, 176, 171);
  padding: 20px 0;
}

.checkout-form {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 0 20px;
  border-radius: 20px;
  width: 80%;
}

.form-group {
  padding: 0;
  margin: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
}

.email-group {
  border-radius: 6px 6px 0 0;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.payment-input {
  background: none;
  border: none;
  font-size: large;
  padding: 11px 15px 11px 0;
  color: #fff;
  width: 100%;
}

.payment-input:focus {
  outline: none;
}

.payment-input::placeholder {
  color: rgb(208, 233, 245);
}

.pay-btn {
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: large;
  border: none;
  background-color: rgb(185, 205, 236);
  width: 100%;
  padding: 11px 15px 11px 0;
  border-radius: 0 0 6px 6px;
  margin-bottom: 50px;
}

.pay-btn:hover {
  cursor: pointer;
  background-color: rgb(147, 177, 222);
}

.error-message {
  font-size: large;
  color: red;
  margin-bottom: 15px;
}

.checkbox-element {
  margin: 20px;
}

.checkbox:hover {
  cursor: pointer;
}

.checkout-insta {
  text-decoration: none;
  color: black;
}

.checkout-insta:hover {
  cursor: pointer;
}

.back-btn {
  background-color: rgb(1, 52, 8);
  color: white;
  margin: 20px 0;
  padding: 5px 15px;
  font-size: large;
  border: none;
}

.back-btn:hover {
  cursor: pointer;
  background-color: rgb(85, 124, 63);
}

.info-box {
  border-top: dotted rgb(156, 111, 37) 3px;
  border-bottom: dotted rgb(156, 111, 37) 3px;
  margin-bottom: 30px;
}

.stripe-message {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stripe-logo {
  width: 60px;
}

/* Desktop version */
@media screen and (min-width: 768px) {
  .checkout-form {
    width: 60%;
  }
}
