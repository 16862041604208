.faq-container {
  width: 80%;
  margin: 30px auto;
  text-align: left;
}

.drawing-image {
  width: 80%;
  padding: 20px 0;
}
